import { FinancialDetails } from '../../../../common';
import React from 'react';

import { BaseSection } from '../base-section/BaseSection';

export const FinancialDetailsUi: React.FC<{ financialDetails?: FinancialDetails }> = ({ financialDetails }) => {
    if (!financialDetails) {
        return null;
    }
    return <BaseSection financialDetails={financialDetails} />;
};
