import React from 'react';
import { getContractsEndpoint, getContractsWithEncryptedEndpoint } from '../../common';
import { DefaultBusinessMarketApiError, DefaultBusinessMarketApiErrorCode } from '@cp-shared-5/common-utilities';
import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-5/frontend-storybook-extensions';
import {
    contractDataWithoutUnpaidBalance,
    contractDataWithValidContracts,
    contractWithAllFields,
    noContractsFoundError,
} from './ExampleData';
import { CpDataApi } from 'cp-xhr';
import { FinancialDetailsMock } from './financial-details';
import { InterveningPartiesMock } from './intervening-parties/InterveningPartiesResponseMocks';

const mockOptions: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 201,
        responseBody: contractDataWithValidContracts,
        delay: 1000,
    },
    'No Contracts Error': {
        status: 502,
        responseBody: noContractsFoundError,
        delay: 1000,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const ContractsMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'Contracts Response',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions,
    url: getContractsEndpoint(),
};

const encryptedEndpointMockOptions: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 201,
        responseBody: contractDataWithValidContracts,
        delay: 1000,
    },
    'Without unpaid balance': {
        status: 201,
        responseBody: contractDataWithoutUnpaidBalance,
        delay: 1000,
    },
    'No Contracts Error': {
        status: 502,
        responseBody: noContractsFoundError,
        delay: 1000,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const EncryptedEndpointContractsMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'Contracts Response',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions: encryptedEndpointMockOptions,
    url: getContractsWithEncryptedEndpoint(contractWithAllFields.encryptedContractNumber),
};

export const ContractsResponseMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [ContractsMock, FinancialDetailsMock(contractWithAllFields), InterveningPartiesMock]);

    return <div>{storyFn()}</div>;
};

export const EncryptedEndpointContractsResponseMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [EncryptedEndpointContractsMock]);

    return <div>{storyFn()}</div>;
};
