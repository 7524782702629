import { Contract } from '../../../../common';
import { Card, LicensePlate } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { ContractCardBody } from './Body';
import { ContractCardHeader } from './Header';
import { ContractUnpaidBalance } from './UnpaidBalance';
import { ContractActionItems } from './ContractActionItems';

export type ContractCardProps = {
    contract: Contract;
};

export const ContractCard: React.FC<ContractCardProps> = ({ contract }) => {
    const { carBrand, carModel, productType, contractNumber, licensePlate } = contract;
    return (
        <Card
            element="article"
            expandable
            title={`${carBrand || ''} ${carModel || ''}`}
            subtitle={`${productType || ''} ${contractNumber || ''}`}
            contentShort={
                <LicensePlate
                    size={'medium'}
                    country={'NL'}
                    countryCode={'NL'}
                    nl={true}
                    registrationNumber={licensePlate || '-'}
                    horizontalStripEu={true}
                    euStars={true}
                />
            }
            className={'u-mb'}
            contentLarge={<ContractCardBody contract={contract} />}
        >
            <ContractCardHeader contract={contract} />
            <ContractUnpaidBalance contract={contract} />
            <ContractActionItems contract={contract} />
        </Card>
    );
};
