import React from 'react';

import { RootState } from 'store/rootReducer';
import { useCmsContent } from '@cp-shared-5/frontend-integration';
import { fetchFaq as fetchPrivateFaq } from './FaqPrivateSlice';
import { FaqWithHandlers } from './FaqView';

export const FaqPrivate: React.FC = () => {
    const { cmsContent: faq, isLoading, loadingError } = useCmsContent(fetchPrivateFaq, (state: RootState) => {
        return state.content.faqPrivate;
    });

    return <FaqWithHandlers hasError={!!loadingError} isLoading={isLoading} faq={faq} />;
};
