import React from 'react';
import { useGetContractBasedApiData } from '@cp-shared-5/frontend-integration';
import { Contract } from '../../../common';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { FinancialDetailsDataSelector } from './FinancialDetailsDataSelector';
import { fetchFinancialDetails } from './FinancialDetailsSlice';
import { FinancialDetailsUi } from './ui';

const FinancialDetailsWithHandlers = withLoadingAndNoConnectionHandler(FinancialDetailsUi);

type FinancialDetailsProps = {
    contract: Contract;
};

export const FinancialDetails: React.FC<FinancialDetailsProps> = ({ contract }) => {
    const { data: financialDetails, isLoading, loadingError } = useGetContractBasedApiData(
        contract.contractNumber,
        fetchFinancialDetails,
        FinancialDetailsDataSelector,
        contract._links?.financialDetails,
    );

    return (
        <FinancialDetailsWithHandlers
            isLoading={isLoading}
            financialDetails={financialDetails}
            hasError={!!loadingError}
        />
    );
};
