import { Contract, NL_TIMEZONE, PostboxTimePeriods } from '../../../common';
import { formatCpDate } from '@cp-shared-5/common-utilities';
import { ContractIdentifierFilterItem, DocumentTypeFilterItem, TimeFrameFilterItem } from '@cp-shared-5/frontend-ui';
import { ContractSelectItem } from 'components/utils';
import moment from 'moment';
import React from 'react';

export const getDocumentTypeFilters = (documentTypes: string[]): DocumentTypeFilterItem[] =>
    documentTypes.map((docType) => ({
        documentType: docType,
        value: docType,
    }));

export const getContractIdentifierFilter = (contracts: Contract[]): ContractIdentifierFilterItem[] =>
    contracts.map((contract) => ({
        contractIdentifier: contract.encryptedContractVersionId,
        reactNode: <ContractSelectItem contract={contract} />,
        value: contract.contractNumber,
    }));

export const getTimeFrameFilterItems = (periodLabels: PostboxTimePeriods): TimeFrameFilterItem[] => [
    {
        from: formatCpDate(moment.tz(NL_TIMEZONE).subtract(3, 'months')).toMoment(),
        to: formatCpDate(moment.tz(NL_TIMEZONE)).toMoment(),
        value: periodLabels.last3months,
        key: '3m',
    },
    {
        from: formatCpDate(moment.tz(NL_TIMEZONE)).subtract(6, 'months').toMoment(),
        to: formatCpDate(moment.tz(NL_TIMEZONE)).toMoment(),
        value: periodLabels.last6months,
        key: '6m',
    },
    {
        from: formatCpDate(moment.tz(NL_TIMEZONE)).subtract(12, 'months').toMoment(),
        to: formatCpDate(moment.tz(NL_TIMEZONE)).toMoment(),
        value: periodLabels.last12months,
        key: '12m',
    },
    {
        from: formatCpDate(moment.tz(NL_TIMEZONE)).subtract(2, 'years').toMoment(),
        to: formatCpDate(moment.tz(NL_TIMEZONE)).toMoment(),
        value: periodLabels.last2years,
        key: '2y',
    },
    {
        from: formatCpDate(moment.tz(NL_TIMEZONE)).subtract(3, 'years').toMoment(),
        to: formatCpDate(moment.tz(NL_TIMEZONE)).toMoment(),
        value: periodLabels.last3years,
        key: '3y',
    },
    {
        from: formatCpDate(moment.tz(NL_TIMEZONE)).subtract(4, 'years').toMoment(),
        to: formatCpDate(moment.tz(NL_TIMEZONE)).toMoment(),
        value: periodLabels.last4years,
        key: '4y',
    },
];
