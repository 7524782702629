import { Contract } from '../../../common';
import { DefinitionListItem /*, useAnalyticsEnterViewportTracker */ } from '@cp-shared-5/frontend-ui';
import { DefinitionList } from '@vwfs-bronson/bronson-react';
import { NoConnectionNotification } from 'components/notifications/no-connection/NoConnectionNotification';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const VehicleDetails: React.FC<{ contract?: Contract }> = ({ contract }) => {
    const { t } = useTranslation('vehicle-details');
    // const { ref } = useAnalyticsEnterViewportTracker('onOpenContractAccordion', 'Vehicle Details');

    if (!contract || !contract.carBrand || !contract.carModel) {
        return <NoConnectionNotification />;
    }

    const { carBrand, carModel, licensePlate, vinCode } = contract;

    const itemList: DefinitionListItem[] = [
        {
            label: t('car-brand-model'),
            value: `${carBrand} ${carModel}`,
        },
        {
            label: t('license-plate'),
            value: licensePlate,
        },
        {
            label: t('vin-code'),
            value: vinCode,
        },
    ].filter((item) => item.value);

    return (
        <>
            {/*<div ref={ref}>*/}
            <DefinitionList split={true}>
                {itemList.map((item, index) => (
                    <DefinitionList.Item title={item.label} key={index}>
                        {item.value}
                    </DefinitionList.Item>
                ))}
            </DefinitionList>
            {/*</div>*/}
        </>
    );
};
