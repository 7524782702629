import { Faq, getFaqPrivateEndpoint } from '../../common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<Faq>({
    contentName: 'faq',
    contentEndpoint: getFaqPrivateEndpoint,
});

export default reducer;
export const fetchFaq = fetchContent;
