import { ContentSection, Heading } from '@vwfs-bronson/bronson-react';
import { TotalEarlySettlement } from 'components/total-early-settlement';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export const TotalEarlySettlementPage: React.FC = () => {
    const { t } = useTranslation('total-early-settlement');
    const { encryptedContractNumber } = useParams<{ encryptedContractNumber: string }>();

    return (
        <ContentSection pageWrapSize="medium">
            <Heading level={1}>{t('headline')}</Heading>
            <TotalEarlySettlement encryptedContractNumber={encryptedContractNumber} />
        </ContentSection>
    );
};
