import { InterveningParty } from '../../../common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-5/common-utilities';
import { createGetContractBasedDataFetchSlice } from '@cp-shared-5/frontend-integration';
import { CpDataApi } from '../../../cp-xhr';

const { reducer, fetchData } = createGetContractBasedDataFetchSlice<
    InterveningParty[],
    DefaultBusinessMarketApiErrorCode
>({
    dataName: 'interveningParties',
    fetchCallback(link: string) {
        return CpDataApi.get(link).then((response) => response.data);
    },
});

export default reducer;
export const fetchInterveningParties = fetchData;
