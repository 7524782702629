import { TFunction } from 'i18next';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import {
    NavigationBar as NavigationBarShared,
    SiteNavItemPropsList,
    SiteNavProps,
    useAnalyticsActionTracker,
    useAuthentication,
} from '@cp-shared-5/frontend-ui';

import {
    cookiePolicyPath,
    dashboardPagePath,
    faqPagePath,
    forbiddenPagePath,
    landingPagePath,
    legalNoticePath,
    notAuthorizedPagePath,
    notFoundPagePath,
    registrationPagePath,
} from './paths';
import { useLogin } from 'auth/useLogin';

const pagesWithoutNavbar = [registrationPagePath()];

const pagesWithoutStaticHeader = [
    landingPagePath(),
    faqPagePath(),
    cookiePolicyPath(),
    legalNoticePath(),
    notAuthorizedPagePath(),
    notFoundPagePath(),
    forbiddenPagePath(),
];

function publicNavigationLinks(t: TFunction, login: () => Promise<void>): SiteNavItemPropsList {
    return [
        {
            url: landingPagePath(),
            label: t('home'),
        },
        {
            label: t('login'),
            onClick: login,
        },
    ];
}

function privateNavigationLinks(t: TFunction, logout: () => Promise<void>): SiteNavItemPropsList {
    return [
        {
            url: dashboardPagePath(),
            label: t('dashboard'),
        },
        {
            url: faqPagePath(),
            label: t('faq'),
        },
        {
            label: t('logout'),
            onClick: logout,
        },
    ];
}

export const NavigationBar: React.FC = () => {
    const { t } = useTranslation('navigation');
    const { isAuthenticated, logout } = useAuthentication();

    const login = useLogin();
    const currentPathName = useLocation().pathname;
    const history = useHistory();
    const location = useLocation();
    const currentPageName = location.pathname.split('/')[1];
    const { onAction: onLogin } = useAnalyticsActionTracker('login');
    const { onAction: onLogout } = useAnalyticsActionTracker('logout');

    const loginFn = useCallback(() => {
        onLogin(currentPageName);
        return login();
    }, [login, onLogin, currentPageName]);

    const logoutFn = useCallback(() => {
        onLogout(currentPageName);
        return logout({ redirectUri: window.location.origin + landingPagePath() });
    }, [logout, onLogout, currentPageName]);

    const onLogoClickCallback = useCallback((): void => {
        const logoRedirectPath = isAuthenticated ? dashboardPagePath() : landingPagePath();
        history.push(logoRedirectPath);
    }, [isAuthenticated, history]);

    const navigationItems = (isAuthenticated
        ? privateNavigationLinks(t, logoutFn)
        : publicNavigationLinks(t, loginFn)
    ).map((navItem) => ({
        ...{ isActive: history.location.pathname === navItem.url },
        ...navItem,
    }));

    const hidden = pagesWithoutNavbar.includes(currentPathName);
    const withStaticHeader = !pagesWithoutStaticHeader.includes(currentPathName);
    const navigation: SiteNavProps = {
        navigationItems,
    };

    return (
        <NavigationBarShared
            onLogoClick={onLogoClickCallback}
            logoAltText={isAuthenticated ? t('dashboard') : t('home')}
            navigation={navigation}
            withStaticHeader={withStaticHeader}
            hidden={hidden}
        />
    );
};
