import React from 'react';
import { Notification } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { NoConnectionNotification } from 'components/notifications/no-connection/NoConnectionNotification';

type errorCode = {
    errorCode: string | undefined;
};

export const ContractOverviewError: React.FC<errorCode> = ({ errorCode }) => {
    const { t } = useTranslation('contracts');
    if (errorCode === undefined) return null;
    const getNotificationProps = (): { status: string; case: string } | undefined => {
        switch (errorCode) {
            case 'NO_CONTRACT_FOUND':
                return {
                    status: 'info',
                    case: 'no-contracts-found',
                };
            case 'MARKET_API_INTERNAL_SERVER_ERROR':
                return {
                    status: 'error',
                    case: 'internal-server-error',
                };
            case 'MARKET_API_SERVICE_UNAVAILABLE':
                return {
                    status: 'error',
                    case: 'service-unavailable',
                };
        }
    };

    const notificationProps = getNotificationProps();

    return notificationProps ? (
        <Notification
            status={notificationProps.status}
            title={t('search-contracts.errors.' + notificationProps.case + '.title')}
            testId={notificationProps.case}
            visible
            showCloseButton={false}
        >
            {t('search-contracts.errors.' + notificationProps.case + '.message')}
        </Notification>
    ) : (
        <NoConnectionNotification />
    );
};
