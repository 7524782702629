import { Contract } from '../../../common';
import { DefaultBusinessMarketApiError, DefaultMarketApiErrors } from '@cp-shared-5/common-utilities';
import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-5/frontend-storybook-extensions';
import { CpDataApi } from '../../../cp-xhr';
import React from 'react';

import { contractWithAllFields } from '../ExampleData';
import { FinancialDetailsWithAllValues } from './base-section/ExampleData';

const mockOptions: MockOptions<DefaultMarketApiErrors> = {
    Success: {
        status: 200,
        responseBody: FinancialDetailsWithAllValues,
        delay: 1000,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const FinancialDetailsMock = (contract: Contract): EndpointMockDescription<DefaultMarketApiErrors> => ({
    name: 'Financial Details Response',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions,
    url: contract._links?.financialDetails || '',
});

const mockFinancialDetailsResponse = (): void => {
    const FinancialDetailsContractMocks = [FinancialDetailsMock(contractWithAllFields)];
    setupMockResponses(CpDataApi, FinancialDetailsContractMocks);
};

export const FinancialDetailsResponseMockWrapper = (storyFn: () => React.ReactNode) => {
    mockFinancialDetailsResponse();
    return <div>{storyFn()}</div>;
};
