import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

import { formatAsDistance, formatAsEmission } from '../common';

i18n.use(LanguageDetector)
    .use(Backend)
    .use(initReactI18next)
    .init({
        fallbackLng: 'nl',
        load: 'languageOnly',
        preload: ['nl'],
        ns: [
            'contracts',
            'dashboard',
            'financial-details',
            'navigation',
            'total-early-settlement',
            'translation',
            'unpaid-balance',
            'vehicle-details',
        ],
        debug: process.env.NODE_ENV === 'development',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
            format(value, format) {
                if (format === 'distance') {
                    return formatAsDistance(value);
                }
                if (format === 'emission') {
                    return formatAsEmission(value);
                }
                return value;
            },
        },
        backend: {
            loadPath: `/locales/{{lng}}/{{ns}}.json`,
        },
        whitelist: ['nl'],
    });

export default i18n;
