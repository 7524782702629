import React from 'react';
import { useTranslation } from 'react-i18next';
import { ExpandableContent, FormSectionGroup } from '@vwfs-bronson/bronson-react';
import { formatCpDate } from '@cp-shared-5/common-utilities';
import { ContractCard } from '../contract-card';
import { Contract, ContractStatus } from '../../../../common';
import { NoConnectionNotification } from 'components/notifications/no-connection/NoConnectionNotification';

export type ContractsOverviewProps = {
    contracts?: Contract[];
    mandatoryFieldsMissing?: boolean;
};

const compareContracts = (a: Contract, b: Contract) => {
    if (!a.contractEndDate) return 1;
    if (!b.contractEndDate) return -1;
    return formatCpDate(a.contractEndDate).toMoment().isAfter(b.contractEndDate) ? 1 : -1;
};

export const ContractsOverview: React.FC<ContractsOverviewProps> = ({ contracts = [], mandatoryFieldsMissing }) => {
    const { t } = useTranslation('contracts');

    const sortedContracts = [...contracts].sort(compareContracts);
    const activeContracts =
        sortedContracts?.filter((contract) => contract.contractStatus === ContractStatus.ACTIVE) || [];
    const expiredContracts =
        sortedContracts?.filter((contract) => contract.contractStatus === ContractStatus.EXPIRED) || [];

    return (
        <>
            {mandatoryFieldsMissing && (
                <div className="u-mb">
                    <NoConnectionNotification />
                </div>
            )}
            {activeContracts.map((contract) => (
                <ContractCard contract={contract} key={contract.contractNumber} />
            ))}
            {!!expiredContracts.length && (
                <>
                    <div className={'u-mt-large'}>
                        <h4 className="u-text-center">{t('inactive-contracts')}</h4>
                    </div>
                    <ExpandableContent
                        lazyRender
                        noBackground
                        pageWrapClassName={'u-pl-none u-pr-none'}
                        triggerLabel={t('click-here')}
                    >
                        <FormSectionGroup>
                            {expiredContracts.map((contract) => (
                                <ContractCard contract={contract} key={contract.contractNumber} />
                            ))}
                        </FormSectionGroup>
                    </ExpandableContent>
                </>
            )}
        </>
    );
};
