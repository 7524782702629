import React, { useEffect, useState } from 'react';
import { TotalEarlySettlementUi } from './ui/TotalEarlySettlementUi';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { CpDataApi } from 'cp-xhr';
import { getPayOffProposalEndpoint, PayOffProposal, PayOffProposalQuery } from '../../common';
import { useContract } from '../contracts';

const TotalEarlySettlementWithHandlers = withLoadingAndNoConnectionHandler(TotalEarlySettlementUi);

export const TotalEarlySettlement: React.FC<{ encryptedContractNumber: string }> = ({ encryptedContractNumber }) => {
    const { data: contractsData, isLoading: isLoadingContract, loadingError: loadingErrorContract } = useContract(
        encryptedContractNumber,
    );

    const [isLoadingPayOffProposal, setIsLoadingPayOffProposal] = useState(false);
    const [payOffProposalLoadingError, setPayOffProposalLoadingError] = useState(false);
    const [payOffProposal, setPayOffProposal] = useState<PayOffProposal>();

    useEffect(() => {
        setIsLoadingPayOffProposal(true);
        if (contractsData?.contracts?.length) {
            const params: PayOffProposalQuery = { contractId: contractsData.contracts[0].encryptedContractVersionId };
            CpDataApi.get<PayOffProposal>(getPayOffProposalEndpoint(), { params })
                .then((response) => {
                    setPayOffProposal(response.data);
                    setPayOffProposalLoadingError(false);
                })
                .catch(() => {
                    setPayOffProposalLoadingError(true);
                })
                .finally(() => {
                    setIsLoadingPayOffProposal(false);
                });
        }
        if (loadingErrorContract) {
            setIsLoadingPayOffProposal(false);
        }
    }, [contractsData, loadingErrorContract]);

    return (
        <TotalEarlySettlementWithHandlers
            payOffProposal={payOffProposal}
            isLoading={isLoadingContract || isLoadingPayOffProposal}
            contract={contractsData?.contracts?.[0]}
            hasError={
                !!loadingErrorContract ||
                !!payOffProposalLoadingError ||
                !!contractsData?.isContractWithoutMandatoryFieldPresent
            }
        />
    );
};
