import React from 'react';
import { Redirect } from 'react-router-dom';

import { createAuthenticationComponent, createAuthorization, createDecoratedRoute } from '@cp-shared-5/frontend-ui';

import { CenteredSpinner } from 'components/centered-spinner';
import { connectionProblemPagePath, landingPagePath, registrationPagePath } from 'components/navigation/paths';
import { getUserRegistrationStatusEndpoint, UserRegistrationStatus } from '../common';
import { CpDataApi } from 'cp-xhr';

const Authentication = createAuthenticationComponent({
    onMissingAuthentication: <Redirect to={landingPagePath()} />,
});

const commonAuthorizationOptions = {
    onLoading: <CenteredSpinner />,
    onError: <Redirect to={connectionProblemPagePath()} />,
};

const { Authorization: UserRegistrationAuthorization, useAuthorizationFlow: useRegistrationFlow } = createAuthorization(
    {
        displayName: 'UserRegistrationAuthorization',
        authorizationDataProvider: async () => {
            const { data: registrationData } = await CpDataApi.get<UserRegistrationStatus>(
                getUserRegistrationStatusEndpoint(),
            );
            return { isAuthorized: registrationData.isRegistered };
        },
        onMissingAuthorization: <Redirect to={registrationPagePath()} />,
        ...commonAuthorizationOptions,
    },
);

const AuthenticatedRoute = createDecoratedRoute('AuthenticatedRoute', Authentication);
const AuthorizedRoute = createDecoratedRoute('AuthorizedRoute', Authentication, UserRegistrationAuthorization);

export { AuthenticatedRoute, AuthorizedRoute, useRegistrationFlow };
export * from './AxiosConfiguration';
