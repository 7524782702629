import { CPDate } from '@cp-shared-5/common-utilities';

export type BankDetails = {
    iban: string;
    paymentMethod?: string;
};

export type IbanChange = {
    contractVersionId?: string;
    isContractIBAN?: boolean;
    originalIBAN?: string;
    iban: string;
    isForeignIBAN: boolean;
    notSameName: boolean;
    ibanName: string;
    startDate?: CPDate;
    requestText: string;
    paymentMethod?: PaymentMethod;
};

export enum PaymentMethod {
    AutomatischeIncasso = 'Automatische incasso',
    BetalingPerBank = 'Betaling per bank',
}
