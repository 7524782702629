import { AuthenticationProvider, AutoLogout, ScrollToTopOnNavigation, Spinner } from '@cp-shared-5/frontend-ui';
import { ConnectedRouter } from 'connected-react-router';
import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { history } from './store/rootReducer';
import { MasterPage } from './pages';
import { LandingPage } from './pages/landing-page';
import {
    dashboardPagePath,
    faqPagePath,
    forbiddenPagePath,
    landingPagePath,
    loginInProgressPagePath,
    notAuthorizedPagePath,
    notFoundPagePath,
    oldStackDashboardPagePath,
    postboxPagePathWithContractId,
    registrationPagePath,
    totalEarlySettlementPagePath,
    unpaidBalancePagePath,
} from './components/navigation/paths';
import { CenteredSpinner } from './components/centered-spinner';
import { AuthenticatedRoute, AuthorizedRoute, AxiosConfiguration } from './auth';
import { LoginInProgressPage } from './pages/login-in-progress-page';
import { FaqPage } from 'pages/faq-page/FaqPage';
import { DashboardPage } from 'pages/dashboard-page';
import { RegistrationPage } from 'pages/registration-page';
import { PostboxPage } from 'pages/postbox-page';
import { NotFoundPage } from 'pages/not-found-page';
import { NotAuthorizedPage } from 'pages/not-authorized-page';
import { ForbiddenPage } from 'pages/forbidden-page';
import { UnpaidBalancePage } from 'pages/unpaid-balance-page';
import { TotalEarlySettlementPage } from 'pages/total-early-settlement-page';

const scrollToTopExceptions: Array<{ from: string; to: string }> = [];

const App: React.FC = () => {
    return (
        <div className="App">
            <Suspense fallback={<Spinner center />}>
                <AuthenticationProvider loadingComponent={<CenteredSpinner />}>
                    <AutoLogout idleTimeInMinutes={5} redirectUri={window.location.origin + landingPagePath()}>
                        <AxiosConfiguration>
                            <ConnectedRouter history={history}>
                                <LastLocationProvider>
                                    <ScrollToTopOnNavigation exceptions={scrollToTopExceptions} />
                                    <MasterPage>
                                        <Switch>
                                            <Route exact path={landingPagePath()}>
                                                <LandingPage />
                                            </Route>
                                            <Route path={faqPagePath()}>
                                                <FaqPage />
                                            </Route>
                                            <AuthenticatedRoute
                                                path={loginInProgressPagePath()}
                                                component={LoginInProgressPage}
                                            />
                                            <AuthorizedRoute path={dashboardPagePath()}>
                                                <DashboardPage />
                                            </AuthorizedRoute>
                                            <AuthorizedRoute path={postboxPagePathWithContractId()}>
                                                <PostboxPage />
                                            </AuthorizedRoute>
                                            <AuthorizedRoute path={unpaidBalancePagePath()}>
                                                <UnpaidBalancePage />
                                            </AuthorizedRoute>
                                            <AuthenticatedRoute path={registrationPagePath()}>
                                                <RegistrationPage />
                                            </AuthenticatedRoute>
                                            <AuthenticatedRoute path={totalEarlySettlementPagePath()}>
                                                <TotalEarlySettlementPage />
                                            </AuthenticatedRoute>
                                            <Route path={forbiddenPagePath()}>
                                                <ForbiddenPage />
                                            </Route>
                                            <Route path={notAuthorizedPagePath()}>
                                                <NotAuthorizedPage />
                                            </Route>
                                            <Route path={notFoundPagePath()}>
                                                <NotFoundPage />
                                            </Route>
                                            <Route path={oldStackDashboardPagePath()}>
                                                <Redirect to={landingPagePath()} />
                                            </Route>
                                            <Route path="*">
                                                <Redirect to={notFoundPagePath()} />
                                            </Route>
                                        </Switch>
                                    </MasterPage>
                                </LastLocationProvider>
                            </ConnectedRouter>
                        </AxiosConfiguration>
                    </AutoLogout>
                </AuthenticationProvider>
            </Suspense>
        </div>
    );
};

export default App;
