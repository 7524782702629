import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import MockAdapter from 'axios-mock-adapter';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import moment from 'moment-timezone';

import { CpContentApi, CpDataApi } from './cp-xhr';
import './localization/i18n';
import store from './store';
import { NL_TIMEZONE } from './common';

const render = () => {
    const App = require('./App').default;
    ReactDOM.render(
        <Provider store={store}>
            <App />
        </Provider>,
        document.getElementById('root'),
    );
};

if (process.env.NODE_ENV === 'development') {
    // Swap the value to enable local mocks
    const setupMockResponse = false;
    const cpDataApiMock = new MockAdapter(CpDataApi);
    const cpContentApiMock = new MockAdapter(CpContentApi);
    if (setupMockResponse) {
        // TODO define local mocks if necessary
    }
    cpContentApiMock.onAny().passThrough();
    cpDataApiMock.onAny().passThrough();
}

moment.tz.setDefault(NL_TIMEZONE);
render();

if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./App', render);
}
