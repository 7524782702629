import { CPDate, DefaultBusinessMarketApiErrorCode } from '@cp-shared-5/common-utilities';
import { MarketApiErrors } from './errorCodes';

export interface ContractsData {
    /**
     * Valid contracts with all mandatory fields present
     */
    contracts?: Contract[];
    /**
     * Flag to identify if there is contract with missing mandatory field to show the corresponding error message
     */
    isContractWithoutMandatoryFieldPresent: boolean;
}

export enum ProductTypeEnum {
    HIRE_PURCHASE = 'HIRE_PURCHASE',
    FINANCIAL_LEASE = 'FINANCIAL_LEASE',
}

export const ProductTypeIds: Readonly<Map<ProductTypeEnum, number[]>> = Object.freeze(
    new Map([
        [ProductTypeEnum.HIRE_PURCHASE, [300002]],
        [ProductTypeEnum.FINANCIAL_LEASE, [300001]],
    ]),
);

export interface Contract {
    encryptedContractVersionId: string;
    encryptedContractNumber: string;
    carBrand?: string;
    carModel?: string;
    productType?: string;
    productTypeEnum?: ProductTypeEnum;
    contractNumber: string;
    licensePlate?: string;
    vinCode?: string;
    nextDueDate?: CPDate;
    nextDueAmount?: number;
    outstandingBalance?: number;
    contractStatus: ContractStatus;
    unpaidBalance?: UnpaidBalance;
    contractEndDate?: CPDate;
    _links?: ContractLinks;
}

export enum ContractStatus {
    ACTIVE = 'ACTIVE',
    EXPIRED = 'EXPIRED',
}

export type UnpaidBalance = {
    unpaidPresent?: boolean;
    nominalAmount?: number;
    arrearsAmount?: number;
    totalUnpaidAmount?: number;
    pendingInstallments?: number;
    interestOnArrears?: number;
    nextDueDate?: CPDate;
    paymentDetailsEntity?: string;
    paymentDetailsReference?: string;
};

export type ContractLinks = {
    financialDetails?: string;
    interveningParties?: string;
    postboxDocuments?: string;
};

export type NoContractFoundError = 'NO_CONTRACT_FOUND' | 'UNDEFINED_SEARCH_PARAM';

export type ContractError = DefaultBusinessMarketApiErrorCode | MarketApiErrors | NoContractFoundError;

export interface SearchContractQuery {
    search: string;
}
