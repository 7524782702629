import React from 'react';
import { useTranslation } from 'react-i18next';
import { Notification, Button, ButtonContainer, Heading, Layout } from '@vwfs-bronson/bronson-react';
import { PayOffProposal, ProductTypeEnum } from '../../../common';
import { SettlementDetails } from '../settlement-details';

type TodaysValidQuoteProps = {
    payOffProposal: PayOffProposal;
    productTypeEnum?: ProductTypeEnum;
    backButtonClick: () => void;
    printButtonClick: () => void;
    futureQuoteSimulationButtonClick: () => void;
};

export const TodaysValidQuote: React.FC<TodaysValidQuoteProps> = ({
    payOffProposal,
    productTypeEnum,
    backButtonClick,
    printButtonClick,
    futureQuoteSimulationButtonClick,
}) => {
    const { t } = useTranslation('total-early-settlement');

    const getHeadlineText = (): JSX.Element | null => {
        switch (productTypeEnum) {
            case ProductTypeEnum.FINANCIAL_LEASE:
                return <span dangerouslySetInnerHTML={{ __html: t('settlement-details.financial-lease-text') }} />;
            case ProductTypeEnum.HIRE_PURCHASE:
                return <span dangerouslySetInnerHTML={{ __html: t('settlement-details.hire-purchase-text') }} />;
            default:
                return null;
        }
    };

    return (
        <>
            <Layout.Item>
                <Heading level={2}>{t('settlement-details.headline')}</Heading>
                {getHeadlineText()}
            </Layout.Item>

            <SettlementDetails payOffProposal={payOffProposal} productTypeEnum={productTypeEnum} />

            <Layout.Item>
                <ButtonContainer center className={'u-mt-small'}>
                    <Button secondary onClick={backButtonClick} testId="dashboardButton" type="btn">
                        {t('translation:editableSectionNav.back')}
                    </Button>
                    <Button
                        secondary
                        onClick={() => printButtonClick()}
                        testId="printButton"
                        icon="printer"
                        iconReversed
                        simple
                    >
                        {t('print-button')}
                    </Button>
                </ButtonContainer>
            </Layout.Item>
            <Layout.Item>
                <Notification
                    className={'u-mt-small'}
                    visible={true}
                    buttons={[
                        <Button key="1" onClick={futureQuoteSimulationButtonClick}>
                            {t('future-quote-simulation.notification.button')}
                        </Button>,
                    ]}
                    title={t('future-quote-simulation.notification.headline')}
                >
                    {t('future-quote-simulation.notification.paragraph')}
                </Notification>
            </Layout.Item>
        </>
    );
};
