import React from 'react';
import { formatAsCurrency, formatAsDate, PayOffProposal, ProductTypeEnum } from '../../../common';
import { NotificationStatus } from '@cp-shared-5/frontend-ui';
import { DescriptionListAccordion, InfoIcon, Layout, Notification } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';

type SettlementDetailsProps = {
    payOffProposal: PayOffProposal;
    productTypeEnum?: ProductTypeEnum;
    isFutureQuoteSimulation?: boolean;
};

export const SettlementDetails: React.FC<SettlementDetailsProps> = ({
    payOffProposal,
    productTypeEnum,
    isFutureQuoteSimulation,
}) => {
    const { t } = useTranslation('total-early-settlement');

    const isPositiveInCurrencyFormat = (value: number | undefined): value is number => {
        return !!value && value.toFixed(2) !== '0.00';
    };

    return (
        <>
            <Layout.Item>
                {payOffProposal.arrearNotification && (
                    <Notification status={NotificationStatus.warning}>
                        {t('settlement-details.arrears-notification')}
                    </Notification>
                )}
            </Layout.Item>
            <Layout.Item>
                <DescriptionListAccordion
                    headerSplit
                    totalLabel={
                        <>
                            {t('settlement-details.labels.calculation-date.text')}
                            <InfoIcon icon="semantic-info" className={'u-text-white'}>
                                {t('settlement-details.labels.calculation-date.tooltip')}
                            </InfoIcon>
                        </>
                    }
                    totalValue={formatAsDate(payOffProposal.calculationDate)}
                >
                    {productTypeEnum === ProductTypeEnum.HIRE_PURCHASE && (
                        <DescriptionListAccordion.Item title={t('settlement-details.labels.headline-expanded')}>
                            {isPositiveInCurrencyFormat(payOffProposal.termsInArrears) && (
                                <DescriptionListAccordion.DL
                                    label={
                                        <>
                                            {t('settlement-details.labels.terms-in-arrears.text')}
                                            <InfoIcon icon="semantic-info">
                                                {t('settlement-details.labels.terms-in-arrears.tooltip')}
                                            </InfoIcon>
                                        </>
                                    }
                                >
                                    {formatAsCurrency(payOffProposal.termsInArrears)}
                                </DescriptionListAccordion.DL>
                            )}
                            {isPositiveInCurrencyFormat(payOffProposal.remainingTermsAmount) && (
                                <DescriptionListAccordion.DL
                                    label={
                                        <>
                                            {t('settlement-details.labels.remaining-terms-amount.text')}
                                            <InfoIcon icon="semantic-info">
                                                {t('settlement-details.labels.remaining-terms-amount.tooltip')}
                                            </InfoIcon>
                                        </>
                                    }
                                >
                                    {formatAsCurrency(payOffProposal.remainingTermsAmount)}
                                </DescriptionListAccordion.DL>
                            )}
                            {isPositiveInCurrencyFormat(payOffProposal.latePaymentInterest) && (
                                <DescriptionListAccordion.DL
                                    label={
                                        <>
                                            {t('settlement-details.labels.late-payment-interest.text')}
                                            <InfoIcon icon="semantic-info">
                                                {t('settlement-details.labels.late-payment-interest.tooltip')}
                                            </InfoIcon>
                                        </>
                                    }
                                >
                                    {formatAsCurrency(payOffProposal.latePaymentInterest)}
                                </DescriptionListAccordion.DL>
                            )}
                            {isPositiveInCurrencyFormat(payOffProposal.prepayments) && (
                                <DescriptionListAccordion.DL
                                    label={
                                        <>
                                            {t('settlement-details.labels.prepayments.text')}
                                            <InfoIcon icon="semantic-info">
                                                {t('settlement-details.labels.prepayments.tooltip')}
                                            </InfoIcon>
                                        </>
                                    }
                                >
                                    {formatAsCurrency(-payOffProposal.prepayments)}
                                </DescriptionListAccordion.DL>
                            )}
                            {isPositiveInCurrencyFormat(payOffProposal.grossPayOffAmount) && (
                                <DescriptionListAccordion.DL
                                    label={
                                        <>
                                            <span style={{ fontWeight: 'bold' }}>
                                                {t('settlement-details.labels.subtotal.text')}
                                            </span>
                                            <InfoIcon icon="semantic-info">
                                                {t('settlement-details.labels.subtotal.tooltip')}
                                            </InfoIcon>
                                        </>
                                    }
                                >
                                    <span style={{ fontWeight: 'bold' }}>
                                        {formatAsCurrency(payOffProposal.grossPayOffAmount)}
                                    </span>
                                </DescriptionListAccordion.DL>
                            )}
                            {isPositiveInCurrencyFormat(payOffProposal.interestRestitution) && (
                                <DescriptionListAccordion.DL
                                    label={
                                        <>
                                            {t('settlement-details.labels.interest-restitution.text')}
                                            <InfoIcon icon="semantic-info">
                                                {t('settlement-details.labels.interest-restitution.tooltip')}
                                            </InfoIcon>
                                        </>
                                    }
                                >
                                    {formatAsCurrency(-payOffProposal.interestRestitution)}
                                </DescriptionListAccordion.DL>
                            )}
                            {isPositiveInCurrencyFormat(payOffProposal.earlyTerminationCost) && (
                                <DescriptionListAccordion.DL
                                    label={
                                        <>
                                            {t('settlement-details.labels.early-termination-cost.text')}
                                            <InfoIcon icon="semantic-info">
                                                {t('settlement-details.labels.early-termination-cost.tooltip')}
                                            </InfoIcon>
                                        </>
                                    }
                                >
                                    {formatAsCurrency(payOffProposal.earlyTerminationCost)}
                                </DescriptionListAccordion.DL>
                            )}
                        </DescriptionListAccordion.Item>
                    )}
                    <DescriptionListAccordion.Item
                        nonCollapsable
                        icon={'semantic-info'}
                        tooltip={t('settlement-details.labels.payoff-amount.tooltip')}
                        title={
                            productTypeEnum === ProductTypeEnum.HIRE_PURCHASE
                                ? t('settlement-details.labels.payoff-amount.text.hire-purchase')
                                : t('settlement-details.labels.payoff-amount.text.financial-lease')
                        }
                        titleSplit={formatAsCurrency(payOffProposal.payOffAmount)}
                    />
                    {isFutureQuoteSimulation && isPositiveInCurrencyFormat(payOffProposal.futureTerms) && (
                        <DescriptionListAccordion.Item
                            nonCollapsable
                            icon={'semantic-info'}
                            tooltip={t('settlement-details.labels.future-terms.tooltip')}
                            title={t('settlement-details.labels.future-terms.text')}
                            titleSplit={formatAsCurrency(-payOffProposal.futureTerms)}
                        />
                    )}
                    {isFutureQuoteSimulation && (
                        <DescriptionListAccordion.Item
                            nonCollapsable
                            icon={'semantic-info'}
                            tooltip={t('settlement-details.labels.future-payoff-amount.tooltip')}
                            title={t('settlement-details.labels.future-payoff-amount.text')}
                            titleSplit={formatAsCurrency(payOffProposal.futurePayoffAmount)}
                        />
                    )}
                </DescriptionListAccordion>
            </Layout.Item>
        </>
    );
};
