import React from 'react';
import { ContentSection, Heading, Layout } from '@vwfs-bronson/bronson-react';
import { Contracts } from 'components/contracts';
import { useTranslation } from 'react-i18next';

export const DashboardPage: React.FC = () => {
    const { t } = useTranslation('dashboard');

    return (
        <ContentSection pageWrapSize="medium">
            <Heading level={1}>{t('headline')}</Heading>
            <span dangerouslySetInnerHTML={{ __html: t('description') }} />
            <Layout>
                <Layout.Item>
                    <Contracts />
                </Layout.Item>
            </Layout>
        </ContentSection>
    );
};
