import React from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationStatus } from '@cp-shared-5/frontend-ui';
import { Notification } from '@vwfs-bronson/bronson-react';

export type NoConnectionNotificationProps = {
    testId?: string;
};

export const NoConnectionNotification: React.FC<NoConnectionNotificationProps> = ({ testId }) => {
    const { t } = useTranslation();
    return (
        <Notification
            status={NotificationStatus.error}
            title={t('error.noConnection.title')}
            testId={testId}
            visible={true}
            showCloseButton={false}
        >
            {t('error.noConnection.text')}
        </Notification>
    );
};
