import React, { useState } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
    Button,
    ButtonContainer,
    Fieldset,
    Form,
    FormField,
    Heading,
    Layout,
    Spinner,
    Notification,
} from '@vwfs-bronson/bronson-react';
import { CPDate, formatCpDate } from '@cp-shared-5/common-utilities';
import { NotificationStatus, preventSubmit, ValidatedDate } from '@cp-shared-5/frontend-ui';
import {
    Contract,
    getPayOffProposalEndpoint,
    longDateFormat,
    PayOffProposal,
    PayOffProposalQuery,
    ProductTypeEnum,
} from '../../../common';
import { CpDataApi } from 'cp-xhr';
import { SettlementDetails } from '../settlement-details';
import { getMinValidDate, validationSchema } from './validationSchema';

type FutureQuoteSimulationProps = {
    backButtonClick: () => void;
    dashboardButtonClick: () => void;
    printButtonClick: (payoffDate: CPDate) => void;
    contract: Contract;
};

type InitialValues = {
    payoffDate: string;
};

export const FutureQuoteSimulation: React.FC<FutureQuoteSimulationProps> = ({
    backButtonClick,
    dashboardButtonClick,
    printButtonClick,
    contract,
}) => {
    const { t, i18n } = useTranslation('total-early-settlement');
    const currentLanguage = i18n.languages[0];
    const translationPrefix = 'future-quote-simulation';

    const [payOffProposal, setPayOffProposal] = useState<PayOffProposal | null>(null);
    const [payOffProposalLoading, setPayOffProposalLoading] = useState<boolean>(false);
    const [payOffProposalError, setPayOffProposalError] = useState<boolean>(false);

    const initialValues: InitialValues = {
        payoffDate: getMinValidDate().format(longDateFormat),
    };

    const handleSubmit = ({ payoffDate }: InitialValues) => {
        setPayOffProposalLoading(true);
        setPayOffProposalError(false);
        setPayOffProposal(null);

        const params: PayOffProposalQuery = {
            contractId: contract.encryptedContractVersionId,
            payoffDate: formatCpDate(payoffDate, longDateFormat).toCpDate(),
        };

        CpDataApi.get<PayOffProposal>(getPayOffProposalEndpoint(), { params })
            .then(({ data }) => {
                setPayOffProposal(data);
            })
            .catch(() => {
                setPayOffProposalError(true);
            })
            .finally(() => {
                setPayOffProposalLoading(false);
            });
    };

    return (
        <>
            <Layout.Item className="u-mb">
                <Heading level={2}>{t(`${translationPrefix}.headline`)}</Heading>
                {
                    <span
                        dangerouslySetInnerHTML={{
                            __html:
                                contract.productTypeEnum === ProductTypeEnum.FINANCIAL_LEASE
                                    ? t(`${translationPrefix}.financial-lease-description`)
                                    : t(`${translationPrefix}.description`),
                        }}
                    />
                }
            </Layout.Item>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema(t, contract.contractEndDate)}
                onSubmit={handleSubmit}
            >
                {({ submitForm, values }) => (
                    <>
                        <Layout.Item>
                            <Form onSubmit={preventSubmit()}>
                                <Fieldset>
                                    <Fieldset.Row>
                                        <ValidatedDate
                                            name="payoffDate"
                                            label={t(`${translationPrefix}.date-form.label`)}
                                            language={currentLanguage}
                                            outputDateFormat="d-m-Y"
                                            isMandatory={true}
                                            value={values.payoffDate}
                                            testId="payoff-date-input"
                                        />
                                    </Fieldset.Row>
                                    <Fieldset.Row>
                                        <FormField type="other">
                                            <ButtonContainer center>
                                                <Button onClick={submitForm} testId="submit-button" type="submit">
                                                    {t(`${translationPrefix}.date-form.calculate-button`)}
                                                </Button>
                                            </ButtonContainer>
                                        </FormField>
                                    </Fieldset.Row>
                                </Fieldset>
                            </Form>
                        </Layout.Item>

                        <Layout.Item className="u-mv">
                            {payOffProposalLoading && <Spinner center />}

                            {payOffProposal && (
                                <SettlementDetails
                                    isFutureQuoteSimulation
                                    payOffProposal={payOffProposal}
                                    productTypeEnum={contract.productTypeEnum}
                                />
                            )}

                            {payOffProposalError && (
                                <Notification
                                    status={NotificationStatus.error}
                                    title={t(`${translationPrefix}.no-connection-error.headline`)}
                                    testId="no-connection-error"
                                    visible={true}
                                    showCloseButton={false}
                                >
                                    {t(`${translationPrefix}.no-connection-error.text`)}
                                </Notification>
                            )}
                        </Layout.Item>
                        <Layout.Item>
                            <ButtonContainer nav>
                                <Button
                                    secondary
                                    onClick={() =>
                                        printButtonClick(formatCpDate(values.payoffDate, longDateFormat).toCpDate())
                                    }
                                    testId="printButton"
                                    icon="printer"
                                    link
                                    iconReversed
                                    simple
                                    disabled={payOffProposalError || !payOffProposal}
                                >
                                    {t('print-button')}
                                </Button>
                            </ButtonContainer>
                            <ButtonContainer center>
                                <Button secondary onClick={backButtonClick} testId="backButton" type="btn">
                                    {t('translation:editableSectionNav.back')}
                                </Button>
                                <Button onClick={dashboardButtonClick} testId="dashboardButton" type="btn">
                                    {t('translation:editableSectionNav.dashboard')}
                                </Button>
                            </ButtonContainer>
                        </Layout.Item>
                    </>
                )}
            </Formik>
        </>
    );
};
