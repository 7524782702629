import { AnyAction, combineReducers, createAction } from '@reduxjs/toolkit';
import financialDetailsReducer from 'components/contracts/financial-details/FinancialDetailsSlice';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import contractsReducer from '../components/contracts/ContractsSlice';
import interveningPartiesReducer from '../components/contracts/intervening-parties/InterveningPartiesSlice';
import encryptedContractReducer from '../components/contracts/EncryptedContractSlice';
import faqPrivateReducer from '../components/faq/FaqPrivateSlice';
import faqPublicReducer from '../components/faq/FaqPublicSlice';
import iconFooterReducer from '../components/icon-footer/IconFooterSlice';
import landingPageTeaserReducer from '../components/landing-page-teaser/LandingPageTeaserSlice';
import legalFooterReducer from '../components/legal-footer/LegalFooterSlice';
import postboxDocumentsReducer from '../components/postbox/PostboxDocumentsSlice';

export const history = createBrowserHistory();

const appReducer = combineReducers({
    router: connectRouter(history),
    financialDetails: financialDetailsReducer,
    interveningParties: interveningPartiesReducer,
    encryptedContract: encryptedContractReducer,
    contracts: contractsReducer,
    postboxDocuments: postboxDocumentsReducer,
    content: combineReducers({
        iconFooter: iconFooterReducer,
        legalFooter: legalFooterReducer,
        landingPageTeaser: landingPageTeaserReducer,
        faqPublic: faqPublicReducer,
        faqPrivate: faqPrivateReducer,
    }),
});

export type RootState = ReturnType<typeof appReducer>;

export const resetStore = createAction('store/reset');

const rootReducer = (state: RootState | undefined, action: AnyAction) => {
    if (action.type === resetStore.toString()) {
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;
