import React from 'react';
import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';
import { usePostboxDocuments } from './usePostboxDocuments';
import { PostboxUi } from './ui';
import { useCmsContent } from 'utils/useCmsContent';
import { PostboxCms } from '../../common';
import { useAnalyticsPageViewTracker } from '@cp-shared-5/frontend-ui';
import { useContract } from '../contracts';

const PostboxWithHandlers = withLoadingAndNoConnectionHandler(PostboxUi);
type PostboxProps = {
    encryptedContractNumber: string;
};

export const Postbox: React.FC<PostboxProps> = ({ encryptedContractNumber }) => {
    const { data: contractsData, isLoading: isLoadingContract, loadingError: loadingErrorContract } = useContract(
        encryptedContractNumber,
    );
    const {
        data: postboxDocuments,
        isLoading: isLoadingDocuments,
        loadingError: postboxDocumentsLoadingError,
    } = usePostboxDocuments(contractsData?.contracts?.[0]);
    const [postboxCms, postboxCmsLoading, loadingErrorPostboxCms] = useCmsContent<PostboxCms>('postbox');

    useAnalyticsPageViewTracker('postbox', !!postboxDocuments);

    return (
        <PostboxWithHandlers
            isLoading={isLoadingContract || isLoadingDocuments || postboxCmsLoading}
            hasError={
                !!loadingErrorPostboxCms ||
                !!loadingErrorContract ||
                !!contractsData?.isContractWithoutMandatoryFieldPresent
            }
            postboxDocuments={postboxDocuments || []}
            isDataFetchingError={!!postboxDocumentsLoadingError}
            postboxCms={postboxCms}
            encryptedContractVersionId={contractsData?.contracts?.[0].encryptedContractVersionId || ''}
        />
    );
};
