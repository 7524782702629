import { Faq } from '../../common';
import { Faq as FaqShared, HeroImage } from '@cp-shared-5/frontend-ui';
import { ContentSection } from '@vwfs-bronson/bronson-react';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import React from 'react';

/**
 * Shared view for Public and Private Faq components
 * @param faq
 */
const FaqView: React.FC<{ faq?: Faq }> = ({ faq }) => {
    const teaser = faq?.teaser;
    const { header, descriptionText, items } = faq?.content || { header: '', items: [] };
    const contentComponent = <FaqShared header={header} descriptionText={descriptionText} items={items} />;
    // useAnalyticsPageViewTracker('faq', !!faq);

    return (
        <>
            {teaser && (
                <HeroImage
                    title={teaser.title}
                    imageUrl={teaser.imageUrl}
                    inverted={!!teaser.isInverted}
                    shallow={teaser.shallow}
                />
            )}
            <ContentSection pageWrapSize="medium">{contentComponent}</ContentSection>
        </>
    );
};

export const FaqWithHandlers = withLoadingAndNoConnectionHandler(FaqView);
