import React from 'react';

import { LegalFooter as LegalFooterUi } from '@cp-shared-5/frontend-ui';

import { useLegalFooter } from './useLegalFooter';
import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';
import { formatCpDate } from '@cp-shared-5/common-utilities';

const LegalFooterWithHandlers = withLoadingAndNoConnectionHandler(LegalFooterUi);

export const LegalFooter: React.FC = () => {
    const { cmsContent: legalFooter, isLoading, loadingError } = useLegalFooter();

    const copyrightText = legalFooter?.copyrightText
        ? `${legalFooter?.copyrightText} ${formatCpDate().toMoment().year()}`
        : undefined;

    return (
        <LegalFooterWithHandlers
            copyrightText={copyrightText}
            legalText={legalFooter?.legalText}
            isLoading={isLoading}
            links={legalFooter?.links || []}
            hasError={!!loadingError}
        />
    );
};
