import React, { useState } from 'react';
import base64ToBlob from 'b64-to-blob';
import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { DefinitionTable, Heading, Layout, LicensePlate, Modal } from '@vwfs-bronson/bronson-react';
import { CPDate, formatCpDate } from '@cp-shared-5/common-utilities';
import { DividedContractHeader, Spinner } from '@cp-shared-5/frontend-ui';
import { CpDataApi } from 'cp-xhr';
import {
    Contract,
    formatAsCurrency,
    getPayOffProposalPdfEndpoint,
    longDateFormat,
    PayOffProposal,
} from '../../../common';
import {
    dashboardPagePath,
    futureQuoteSimulationPath,
    offlinePaymentPath,
    totalEarlySettlementPagePath,
} from 'components/navigation/paths';
import { OfflinePayment } from '../offline-payment';
import { TodaysValidQuote } from '../todays-valid-quote';
import { FutureQuoteSimulation } from '../future-quote-simulation';

type TotalEarlySettlementUiProps = {
    contract?: Contract;
    payOffProposal?: PayOffProposal;
};

export const TotalEarlySettlementUi: React.FC<TotalEarlySettlementUiProps> = ({ contract, payOffProposal }) => {
    const { path } = useRouteMatch();
    const { t } = useTranslation('total-early-settlement');
    const history = useHistory();
    const [showErrorDialogue, setShowErrorDialogue] = useState(false);
    const [isLoadingPDF, setIsLoadingPDF] = useState(false);

    if (!contract || !payOffProposal) {
        return null;
    }

    const {
        carBrand,
        carModel,
        productType,
        productTypeEnum,
        contractNumber,
        licensePlate,
        nextDueAmount,
        outstandingBalance,
        contractEndDate,
        encryptedContractVersionId,
    } = contract;

    const carInformation = `${carBrand || ''} ${carModel || ''}`;

    const rightSideContent = (
        <DefinitionTable>
            <Heading level={4}>{t('divided-contract-card.section-title')}</Heading>
            <DefinitionTable.Section>
                <DefinitionTable.Item title={t('divided-contract-card.next-due-amount')}>
                    {formatAsCurrency(nextDueAmount)}
                </DefinitionTable.Item>
                <DefinitionTable.Item title={t('divided-contract-card.outstanding-balance')}>
                    {formatAsCurrency(outstandingBalance)}
                </DefinitionTable.Item>
                <DefinitionTable.Item title={t('divided-contract-card.contract-end-date')}>
                    {formatCpDate(contractEndDate)?.format('DD-MM-YYYY')}
                </DefinitionTable.Item>
            </DefinitionTable.Section>
        </DefinitionTable>
    );

    const redirectToDashboard = (): void => {
        history.push(dashboardPagePath());
    };

    const redirectToFutureQuoteSimulation = (): void => {
        history.push(futureQuoteSimulationPath(contract.encryptedContractNumber));
    };

    const redirectToTodaysValidQuote = (): void => {
        history.push(totalEarlySettlementPagePath(contract.encryptedContractNumber));
    };

    const generatePDF = (payoffDate?: CPDate): void => {
        setIsLoadingPDF(true);
        const link = getPayOffProposalPdfEndpoint(encryptedContractVersionId);
        !link
            ? setShowErrorDialogue(true)
            : CpDataApi.get(link, { params: { contractNumber, payoffDate } })
                  .then((response) => {
                      const fileContent = response.data;
                      const pdfContentType = 'application/pdf';
                      const pdfBlob = base64ToBlob(fileContent, pdfContentType);
                      const filename = `total-early-settlement-${encryptedContractVersionId}-${
                          payoffDate
                              ? formatCpDate(payoffDate).format(longDateFormat)
                              : formatCpDate().format(longDateFormat)
                      }.pdf`;
                      saveAs(pdfBlob, filename);
                  })
                  .catch(() => {
                      setShowErrorDialogue(true);
                  })
                  .finally(() => {
                      setIsLoadingPDF(false);
                  });
    };

    return (
        <>
            {isLoadingPDF && <Spinner fullPage={true} />}
            <Layout>
                <Layout.Item>
                    <DividedContractHeader
                        contractIdentifier={
                            <LicensePlate
                                size={'medium'}
                                country={'NL'}
                                countryCode={'NL'}
                                nl={true}
                                registrationNumber={licensePlate || '-'}
                                horizontalStripEu={true}
                                euStars={true}
                            />
                        }
                        carInformation={carInformation}
                        contractCategory={productType || ''}
                        contractNumber={contractNumber}
                        rightSideContent={rightSideContent}
                    />
                </Layout.Item>
                <Switch>
                    <Route path={offlinePaymentPath()}>
                        <OfflinePayment
                            backButtonClick={redirectToTodaysValidQuote}
                            dashboardButtonClick={redirectToDashboard}
                            printButtonClick={generatePDF}
                            payOffProposal={payOffProposal}
                            contract={contract}
                        />
                    </Route>
                    <Route path={futureQuoteSimulationPath()}>
                        <FutureQuoteSimulation
                            backButtonClick={redirectToTodaysValidQuote}
                            dashboardButtonClick={redirectToDashboard}
                            printButtonClick={generatePDF}
                            contract={contract}
                        />
                    </Route>
                    <Route path={path}>
                        <TodaysValidQuote
                            productTypeEnum={productTypeEnum}
                            payOffProposal={payOffProposal}
                            backButtonClick={redirectToDashboard}
                            printButtonClick={generatePDF}
                            futureQuoteSimulationButtonClick={redirectToFutureQuoteSimulation}
                        />
                    </Route>
                </Switch>
            </Layout>
            <Modal
                shown={showErrorDialogue}
                status="error"
                buttonConfirmText={t('payment-details.download-modal.button-text')}
                title={t('payment-details.download-modal.modal-text')}
                onConfirm={() => setShowErrorDialogue(false)}
                onClickOutside={() => setShowErrorDialogue(false)}
                onClose={() => setShowErrorDialogue(false)}
                testId={'error-modal'}
            />
        </>
    );
};
