import React from 'react';
import { UnpaidBalanceDetailsUi } from './ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { useContract } from '../contracts';

const UnpaidBalanceWithHandlers = withLoadingAndNoConnectionHandler(UnpaidBalanceDetailsUi);
type UnpaidBalanceProps = {
    encryptedContractNumber: string;
};

export const UnpaidBalanceDetails: React.FC<UnpaidBalanceProps> = ({ encryptedContractNumber }) => {
    const { data: contractsData, isLoading, loadingError } = useContract(encryptedContractNumber);

    return (
        <UnpaidBalanceWithHandlers
            contract={contractsData?.contracts?.[0]}
            isLoading={isLoading}
            hasError={
                !!loadingError ||
                !!contractsData?.isContractWithoutMandatoryFieldPresent ||
                !contractsData?.contracts?.[0].unpaidBalance?.unpaidPresent
            }
        />
    );
};
