import React from 'react';
import {
    HeroImage as LandingPageTeaserUi,
    useAnalyticsActionTracker,
    useAnalyticsPageViewTracker,
} from '@cp-shared-5/frontend-ui';
import { useLandingPageTeaser } from './useLandingPageTeaser';
import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';
import { useLogin } from 'auth/useLogin';

export const LandingPageTeaser: React.FC = () => {
    const { cmsContent: landingPageTeaser, isLoading, loadingError } = useLandingPageTeaser();
    const login = useLogin();

    const LandingPageTeaserWithHandlers = withLoadingAndNoConnectionHandler(LandingPageTeaserUi);

    const { onAction } = useAnalyticsActionTracker('login');
    useAnalyticsPageViewTracker('landingPage', true);

    const onClick = async (): Promise<void> => {
        onAction();
        await login();
    };

    return (
        <LandingPageTeaserWithHandlers
            isLoading={isLoading}
            hasError={!!loadingError}
            title={landingPageTeaser?.title || ''}
            subTitle={landingPageTeaser?.subTitle}
            text={landingPageTeaser?.text}
            imageUrl={landingPageTeaser?.imageUrl || ''}
            buttonText={landingPageTeaser?.buttonText}
            clickHandler={onClick}
            inverted={!!landingPageTeaser?.isInverted}
        />
    );
};
