import { FinancialDetails, FinancialDetailsCms, NL_TIMEZONE } from '../../../../common';
import { formatCpDate } from '@cp-shared-5/common-utilities';
import moment from 'moment-timezone';
//moment.tz.setDefault("Europe/Berlin");

export const FinancialDetailsWithAllValues: FinancialDetails = {
    contractStartDate: formatCpDate(moment.tz('2015-11-10', NL_TIMEZONE)).toCpDate(),
    contractEndDate: formatCpDate(moment.tz('2030-02-01', NL_TIMEZONE)).toCpDate(),
    firstPaymentDate: formatCpDate(moment.tz('2015-11-10', NL_TIMEZONE)).toCpDate(),
    lastPaymentDate: formatCpDate(moment.tz('2030-02-01', NL_TIMEZONE)).toCpDate(),
    contractDuration: '48 Maanden',
    curbPrice: 2351.03,
    downPayment: 2351.03,
    tradeInAmount: 2351.03,
    internalRepayment: 2351.03,
    nominalAmount: 2351.03,
    creditCompensation: 2351.03,
    interest: 0.04,
    nominalInterest: 0.04,
    annualPercentageRate: 0.07,
    finalPaymentAmount: 75000,
    totalAmountToBePaid: 75000,
    monthlyDueDay: '14e van de maand',
};

export const sampleCms: FinancialDetailsCms = {
    financialDetails: {
        headline: 'SampleHeadline',
        contractStartDate: {
            label: 'Contract Startdatum',
            tooltip: '',
        },
        contractEndDate: {
            label: 'Contract Einddatum',
            tooltip: '',
        },
        firstPaymentDate: {
            label: 'Datum eerste termijn',
            tooltip: '',
        },
        lastPaymentDate: {
            label: 'Datum laatste termijn',
            tooltip: '',
        },
        contractDuration: {
            label: 'Looptijd',
            tooltip: '',
        },
        curbPrice: {
            label: 'Rijklaarprijs',
            tooltip: 'Consumentenprijs (aankoopprijs) van de auto inclusief afleverkosten',
        },
        downPayment: {
            label: 'Aanbetaling',
            tooltip: 'Het gedeelte van de rijklaarprijs dat u zelf inlegt.',
        },
        tradeInAmount: {
            label: 'Inruilprijs',
            tooltip: 'Waarde van de ingeruilde auto',
        },
        internalRepayment: {
            label: 'Ingebrachte restschuld(en)',
            tooltip: 'Overgenomen restschuld ingeruilde auto die wordt geherfinancierd middels dit contract',
        },
        nominalAmount: {
            label: 'Kredietbedrag',
            tooltip: 'Dit is het bedrag dat u heeft gefinancierd zonder de te betalen rente.',
        },
        creditCompensation: {
            label: 'Kredietvergoeding',
            tooltip:
                'De kredietvergoeding is de te betalen rente uitgedrukt in euro’s. Oftewel de kosten die u betaalt voor de financiering',
        },
        interest: {
            label: 'Debetrentevoet',
            tooltip: 'Het rentepercentage van de financiering. De rente staat vast gedurende de gehele looptijd.',
        },
        nominalInterest: {
            label: 'Nominale rente',
            tooltip: 'Het rentepercentage van de financiering. De rente staat vast gedurende de gehele looptijd.',
        },
        annualPercentageRate: {
            label: 'Jaarlijks kostenpercentage',
            tooltip:
                'Dit is de debetrentevoet inclusief extra kosten voor het afsluiten van het krediet. Omdat wij geen extra kosten kennen is dit percentage gelijk aan de debetrentevoet',
        },
        finalPaymentAmount: {
            label: 'Slottermijn',
            tooltip:
                'De slottermijn betaalt u samen met de laatste maandtermijn. Over dit deel wordt gedurende de looptijd van de financiering alleen rente en geen aflossing betaald.',
        },
        totalToBePaid: {
            label: 'Totaal te betalen bedrag',
            tooltip:
                'Dit is het geleende bedrag vermeerderd met de Kredietvergoeding. Bij betaalachterstanden wordt aanvullend een achterstandsrente in rekening gebracht.',
        },
        monthlyDueDay: {
            label: 'Maandelijks incasso moment',
            tooltip:
                'De dag van de maand waarop de maandtermijn wordt afgeschreven of de uiterste datum waarop de betaling ontvangen moet zijn.',
        },
    },
};
