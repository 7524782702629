import { Contract, PostboxDocument } from '../../common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-5/common-utilities';
import { AbstractDataState, useGetContractBasedApiData } from '@cp-shared-5/frontend-integration';
import { PostboxDocumentsDataSelector } from './PostboxDocumentsSelector';
import { fetchPostboxDocuments } from './PostboxDocumentsSlice';

export function usePostboxDocuments(
    contract?: Contract,
): AbstractDataState<PostboxDocument[], DefaultBusinessMarketApiErrorCode> {
    return useGetContractBasedApiData(
        contract?.contractNumber || '',
        fetchPostboxDocuments,
        PostboxDocumentsDataSelector,
        contract?._links?.postboxDocuments,
    );
}
