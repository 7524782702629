import React from 'react';
import { useGetContractBasedApiData } from '@cp-shared-5/frontend-integration';
import { Contract } from '../../../common';
import { withLoadingAndNoConnectionHandler } from '../../integration-wrapper';
import { InterveningPartiesDataSelector } from './InterveningPartiesDataSelector';
import { fetchInterveningParties } from './InterveningPartiesSlice';
import { InterveningPartiesUi } from './ui';

const InterveningPartiesWithWrappers = withLoadingAndNoConnectionHandler(InterveningPartiesUi);

type InterveningPartiesProps = {
    contract: Contract;
};

export const InterveningParties: React.FC<InterveningPartiesProps> = ({ contract }) => {
    const { data: interveningParties, isLoading, loadingError } = useGetContractBasedApiData(
        contract.contractNumber,
        fetchInterveningParties,
        InterveningPartiesDataSelector,
        contract._links?.interveningParties,
    );

    return (
        <InterveningPartiesWithWrappers isLoading={isLoading} hasError={!!loadingError} parties={interveningParties} />
    );
};
