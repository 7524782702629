import React, { useState } from 'react';
import { SearchContractForm, ContractsOverview } from './ui';
import { CpDataApi } from 'cp-xhr';
import { Spinner } from '@cp-shared-5/frontend-ui';
import { parseErrorResponse } from '@cp-shared-5/frontend-integration';
import { Contract, SearchContractQuery, getContractsEndpoint, ContractError } from '../../common';
import { ContractOverviewError } from './ui/contracts-overview/ContractsOverviewError';

export const Contracts: React.FC = () => {
    const [contracts, setContracts] = useState<Contract[]>([]);
    const [mandatoryFieldsMissing, setMandatoryFieldsMissing] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [fetchingError, setFetchingError] = useState<string | undefined>();

    const onSubmit = (values: SearchContractQuery) => {
        setIsSubmitting(true);
        CpDataApi.get(getContractsEndpoint(), { params: values })
            .then(({ data }) => {
                setContracts(data.contracts);
                setMandatoryFieldsMissing(data.isContractWithoutMandatoryFieldPresent);
                setFetchingError(undefined);
            })
            .catch((error) => {
                setFetchingError(parseErrorResponse<ContractError>(error)?.code || 'unknown error');
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    const contractsOverview = !!fetchingError ? (
        <ContractOverviewError errorCode={fetchingError} />
    ) : (
        <ContractsOverview contracts={contracts} mandatoryFieldsMissing={mandatoryFieldsMissing} />
    );

    return (
        <>
            <SearchContractForm onSubmit={onSubmit} />
            {isSubmitting ? <Spinner center fullPage /> : contractsOverview}
        </>
    );
};
