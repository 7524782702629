import React from 'react';

import { RootState } from 'store/rootReducer';
import { useCmsContent } from '@cp-shared-5/frontend-integration';
import { fetchFaq as fetchPublicFaq } from './FaqPublicSlice';
import { FaqWithHandlers } from './FaqView';

export const FaqPublic: React.FC = () => {
    const { cmsContent: faq, isLoading, loadingError } = useCmsContent(fetchPublicFaq, (state: RootState) => {
        return state.content.faqPublic;
    });

    return <FaqWithHandlers hasError={!!loadingError} isLoading={isLoading} faq={faq} />;
};
