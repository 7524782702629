import React from 'react';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { IconFooter as IconFooterUi } from '@cp-shared-5/frontend-ui';
import { useIconFooter } from './useIconFooter';
import { Link } from '@vwfs-bronson/bronson-react';
import { IconFooterItem, IconFooterLink } from '../../common';

const IconFooterWithHandlers = withLoadingAndNoConnectionHandler(IconFooterUi);

const isNormalLink = (normalLink?: boolean, link?: string): boolean => normalLink || !!link?.includes('mailto:');

const centeredSpanProps = { style: { display: 'block' }, className: 'u-text-center' };

const createLinks = (item?: IconFooterItem): React.ReactNode[] =>
    (item?.links || []).map((link: IconFooterLink) => (
        <span {...centeredSpanProps} key={link.linkText}>
            <Link
                key={link.linkText}
                href={link.linkUrl}
                normalLink={isNormalLink(link.normalLink || false, link.linkUrl)}
                target={link.target}
                icon={link.withIcon ? 'semantic-forward' : ''}
                iconClassName="c-icon--small"
            >
                {link.linkText}
            </Link>
            <br />
        </span>
    ));
export const IconFooter: React.FC = () => {
    const { cmsContent: iconFooter, isLoading, loadingError } = useIconFooter();
    const { items, title } = iconFooter || {};

    const itemsText = (items || []).map((item: IconFooterItem) => ({
        ...item,
        text: (
            <>
                <span
                    key={`text-${item.text as string}`}
                    dangerouslySetInnerHTML={{ __html: (item.text as string) || '' }}
                />
                <span
                    {...centeredSpanProps}
                    key={`second-line-${item.text as string}`}
                    dangerouslySetInnerHTML={{ __html: (item.textSecondLine as string) || '' }}
                />
                <br />
                {createLinks(item)}
            </>
        ),
    }));

    return <IconFooterWithHandlers isLoading={isLoading} hasError={!!loadingError} title={title} items={itemsText} />;
};
