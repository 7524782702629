import React, { useEffect, useState } from 'react';
import { CustomerVerificationError, SmsCodeContent, SmsSendingError, SmsSendingInfo } from '../../common';
import { Error, WithDefaultCpIntegrationErrors } from '@cp-shared-5/common-utilities';
import { handleSendSms, handleSubmitForm } from './Utils';
import { SmsIdentificationUi } from './ui';
import { Spinner } from '@cp-shared-5/frontend-ui';
import { useCmsContent } from '../../utils/useCmsContent';
import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';
import { dashboardPagePath } from '../navigation/paths';
import { ContentSection, PageWrap } from '@vwfs-bronson/bronson-react';

const SmsIdentificationWithHandlers = withLoadingAndNoConnectionHandler(SmsIdentificationUi);
const MAX_SMS_LIMIT = 3;
type RequestProps = {
    isLoading: boolean;
    error?: Error<WithDefaultCpIntegrationErrors<SmsSendingError | CustomerVerificationError>>;
};

export const SmsIdentification: React.FC = () => {
    const [smsSendingRequest, setSmsSendingRequest] = useState<RequestProps>({ isLoading: true });
    const [formSubmissionRequest, setFormSubmissionRequest] = useState<RequestProps>({ isLoading: false });
    const [isMaxSmsSend, setIsMaxSmsSend] = useState(false);
    const [isResendLinkHidden, setIsResendLinkHidden] = useState(false);
    const [smsPageContent, isContentLoading, contentLoadingError] = useCmsContent<SmsCodeContent>('sms-code-page');

    const sendSmsCode = (): void => {
        setIsResendLinkHidden(true);
        const onSuccess = (data: SmsSendingInfo): void => {
            const { numberOfSmsSent } = data;
            numberOfSmsSent > MAX_SMS_LIMIT && setIsMaxSmsSend(true);
            numberOfSmsSent <= MAX_SMS_LIMIT && setIsResendLinkHidden(false);
            setSmsSendingRequest({ isLoading: false });
        };

        const onError = (error: Error<WithDefaultCpIntegrationErrors<SmsSendingError>>): void | null => {
            error.code === 'CUSTOMER_NOT_FOUND' ? setIsResendLinkHidden(true) : setIsResendLinkHidden(false);
            setSmsSendingRequest({ isLoading: false, error });
        };

        handleSendSms(onSuccess, onError);
    };

    const handleSubmit = ({ smsCode }: { smsCode: string }): void => {
        setFormSubmissionRequest({ isLoading: true });
        const onSuccess = (): void => {
            window.location.assign(dashboardPagePath());
        };
        const onError = (error: Error<WithDefaultCpIntegrationErrors<CustomerVerificationError>>): void => {
            error.code === 'MAX_VERIFICATIONS_NR_REACHED' && setIsResendLinkHidden(true);
            setFormSubmissionRequest({ isLoading: false, error });
        };

        handleSubmitForm(smsCode, onSuccess, onError);
    };

    useEffect(() => {
        sendSmsCode();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ContentSection>
            <PageWrap size={'xsmall'}>
                {formSubmissionRequest.isLoading && <Spinner fullPage={true} />}
                <SmsIdentificationWithHandlers
                    isLoading={isContentLoading || smsSendingRequest.isLoading}
                    hasError={!!contentLoadingError}
                    smsPageContent={smsPageContent}
                    isMaxSmsSend={isMaxSmsSend}
                    isResendLinkHidden={isResendLinkHidden}
                    sendSmsCode={sendSmsCode}
                    handleSubmit={handleSubmit}
                    sendSmsErrorCode={smsSendingRequest?.error?.code}
                    verificationErrorCode={formSubmissionRequest?.error?.code}
                />
            </PageWrap>
        </ContentSection>
    );
};
