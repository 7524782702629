import { CpContentApi } from 'cp-xhr';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const cmsStorage: { [key: string]: any } = {}; // eslint-disable-line @typescript-eslint/no-explicit-any

export const useCmsContent = <T>(fileName: string): [T | undefined, boolean, string | undefined] => {
    const [cmsContent, setCmsContent] = useState<T | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState();

    const { i18n } = useTranslation();
    const language = i18n.languages[0];

    useEffect(() => {
        const brand = process.env.REACT_APP_WEBSITE_BRAND;
        const path = `/${brand}/${language}/${fileName}.json`;

        if (cmsStorage[path]) {
            setCmsContent(cmsStorage[path] as T);
            setIsLoading(false);
            return;
        }

        CpContentApi.get(path)
            .then(({ data }) => {
                cmsStorage[path] = data;
                setCmsContent(data);
            })
            .catch((e) => {
                setError(e.message);
            })
            .finally(() => setIsLoading(false));
        return;
    }, [fileName, language]);

    return [cmsContent, isLoading, error];
};
