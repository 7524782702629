import { HeroImage } from '@cp-shared-5/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import React from 'react';
import { useCmsContent } from 'utils/useCmsContent';
import { ErrorPageCms } from '../../common';
import { useHistory } from 'react-router-dom';

type ErrorType = 'not-found-error' | 'forbidden-error' | 'unauthorized-error';

export type ErrorPageProps = {
    type: ErrorType;
};

export const ErrorPage: React.FC<ErrorPageProps> = ({ type }) => {
    const [cmsContent, isCmsLoading, cmsError] = useCmsContent<ErrorPageCms>(type);
    const history = useHistory();
    const HeroImageWithHandlers = withLoadingAndNoConnectionHandler(HeroImage);

    return (
        <HeroImageWithHandlers
            isLoading={isCmsLoading}
            hasError={!!cmsError}
            pretitle={cmsContent?.pretitle}
            title={cmsContent?.title || ''}
            subTitle={<span dangerouslySetInnerHTML={{ __html: cmsContent?.subTitle || '' }} />}
            buttonText={cmsContent?.buttonText}
            clickHandler={() => history.goBack()}
        />
    );
};
