import React from 'react';
import { Contract } from '../../../../../common';
import { VehicleDetails } from 'components/contracts/vehicle-details';
import { FinancialDetails } from 'components/contracts/financial-details';
import { Accordion, AccordionItem } from '@cp-shared-5/frontend-ui';
import { useTranslation } from 'react-i18next';
import { InterveningParties } from 'components/contracts/intervening-parties';

export type ContractCardBodyProps = {
    contract: Contract;
};

export const ContractCardBody: React.FC<ContractCardBodyProps> = ({ contract }) => {
    const { t } = useTranslation('vehicle-details');
    const { t: ft } = useTranslation('financial-details');
    const { t: ct } = useTranslation('contracts');

    return (
        <Accordion lazyRender>
            <AccordionItem title={ft('financial-details.headline')}>
                <FinancialDetails contract={contract} />
            </AccordionItem>
            <AccordionItem title={t('section-headline')}>
                <VehicleDetails contract={contract} />
            </AccordionItem>
            <AccordionItem title={ct('intervening-parties.headline')}>
                <InterveningParties contract={contract} />
            </AccordionItem>
        </Accordion>
    );
};
