import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, Notification } from '@vwfs-bronson/bronson-react';
import { NotificationStatus } from '@cp-shared-5/frontend-ui';
import { Contract } from '../../../../../common';
import { unpaidBalancePagePath } from 'components/navigation/paths';

export type ContractCardUnpaidBalanceProps = {
    contract: Contract;
};

export const ContractUnpaidBalance: React.FC<ContractCardUnpaidBalanceProps> = ({ contract }) => {
    const history = useHistory();
    const { t } = useTranslation('contracts');

    if (!contract.unpaidBalance?.unpaidPresent) return null;

    const handleClick = () => history.push(unpaidBalancePagePath(contract.encryptedContractNumber));

    return (
        <Notification
            status={NotificationStatus.warning}
            title={t('unpaid-balance.headline')}
            text={t('unpaid-balance.text')}
            className={'u-mb'}
            visible={true}
            showCloseButton={false}
            button={[
                <Button key="1" secondary className="u-bg-white u-mt" onClick={handleClick}>
                    {t('unpaid-balance.button')}
                </Button>,
            ]}
        />
    );
};
