export function getContractsEndpoint(): string {
    return '/contracts';
}

export function getContractsWithEncryptedEndpoint(encryptedContractNumber: string): string {
    return `/contracts/${encryptedContractNumber}`;
}

export function getInterveningPartiesEndpoint(contractId: string): string {
    return `${getContractsEndpoint()}/${contractId}/intervening-parties`;
}

export function getPayOffProposalPdfEndpoint(contractId: string): string {
    return `${getContractsEndpoint()}/${contractId}/documents/payOffProposal`;
}
