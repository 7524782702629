export function autoLogoutPath(): string {
    return '/auto-logout';
}

export function notFoundPagePath(): string {
    return '/not-found';
}

export function registrationPagePath(): string {
    return '/register';
}

export function notAuthorizedPagePath(): string {
    return '/not-authorized';
}

export function forbiddenPagePath(): string {
    return '/forbidden';
}

export function connectionProblemPagePath(): string {
    return '/connection-problem';
}

export function oldStackDashboardPagePath(): string {
    return '/customer-portal/nl/public/index';
}

export function dashboardPagePath(): string {
    return '/dashboard';
}

export function faqPagePath(): string {
    return '/faq';
}

export function cookiePolicyPath(): string {
    return '/cookie-policy';
}

export function legalNoticePath(): string {
    return '/legal-notice';
}

export function landingPagePath(): string {
    return '/';
}

export function postboxPagePathWithContractId(encryptedContractNumber = ':encryptedContractNumber'): string {
    return `/contracts/${encryptedContractNumber}/postbox`;
}

export function loginInProgressPagePath(): string {
    return '/login-in-progress';
}

export function unpaidBalancePagePath(encryptedContractNumber = ':encryptedContractNumber'): string {
    return `/unpaid-balance/${encryptedContractNumber}`;
}

export function totalEarlySettlementPagePath(encryptedContractNumber = ':encryptedContractNumber'): string {
    return `/total-early-settlement/${encryptedContractNumber}`;
}

export function offlinePaymentPath(encryptedContractNumber = ':encryptedContractNumber'): string {
    return `${totalEarlySettlementPagePath(encryptedContractNumber)}/offline-payment`;
}

export function futureQuoteSimulationPath(encryptedContractNumber = ':encryptedContractNumber'): string {
    return `${totalEarlySettlementPagePath(encryptedContractNumber)}/future-quote-simulation`;
}
