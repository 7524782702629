import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { Card, Form, Fieldset, Layout, Button, FormField } from '@vwfs-bronson/bronson-react';
import { preventSubmit, ValidatedInput } from '@cp-shared-5/frontend-ui';
import { SearchContractQuery } from '../../../../common';

export type SearchContractFormProps = {
    onSubmit: (values: SearchContractQuery) => void;
};

const validationSchema = (t: TFunction) => {
    return Yup.object().shape({
        search: Yup.string().trim().required(t('search-contracts.errors.input-required')),
    });
};

export const SearchContractForm: React.FC<SearchContractFormProps> = ({ onSubmit }) => {
    const { t } = useTranslation('contracts');

    const initialValues: SearchContractQuery = {
        search: '',
    };

    return (
        <Card className="u-mb" element="article">
            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema(t)}>
                {({ submitForm }) => (
                    <Form onSubmit={preventSubmit()} data-testid="edit-form">
                        <Fieldset>
                            <Fieldset.Row>
                                <Layout>
                                    <Layout.Item>{t(`search-contracts.search-field-label`)}</Layout.Item>
                                    <Layout.Item default="2/3" s="1/1" className="u-mt-xsmall">
                                        <ValidatedInput
                                            name="search"
                                            testId="search"
                                            label=""
                                            addonIcon="semantic-search"
                                        />
                                    </Layout.Item>
                                    <Layout.Item default="1/3" s="1/1" className="u-mt-xsmall">
                                        <FormField type="other">
                                            <Button onClick={submitForm} testId="submit-button" type="submit">
                                                {t(`search-contracts.search-button`)}
                                            </Button>
                                        </FormField>
                                    </Layout.Item>
                                </Layout>
                            </Fieldset.Row>
                        </Fieldset>
                    </Form>
                )}
            </Formik>
        </Card>
    );
};
