import { Contract, formatAsCurrency, PayOffProposal } from '../../../common';
import { formatCpDate } from '@cp-shared-5/common-utilities';
import { DataOverview, Notification, NotificationStatus } from '@cp-shared-5/frontend-ui';
import { Button, ButtonContainer, DefinitionList, DefinitionTable, Heading, Layout } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

type OfflinePaymentProps = {
    backButtonClick: () => void;
    dashboardButtonClick: () => void;
    printButtonClick: () => void;
    payOffProposal?: PayOffProposal;
    contract: Contract;
};

export const OfflinePayment: React.FC<OfflinePaymentProps> = ({
    backButtonClick,
    dashboardButtonClick,
    printButtonClick,
    payOffProposal,
    contract,
}) => {
    const { t } = useTranslation('total-early-settlement');

    return (
        <>
            <Layout.Item>
                <Heading level={2}>{t('payment-details.headline')}</Heading>
                {t('payment-details.text')}
            </Layout.Item>
            <Layout.Item>
                <DataOverview title={t('bank-transfer-details.headline')}>
                    <Layout divider className="row">
                        <Layout.Item default="1/2">
                            <DefinitionList>
                                <DefinitionList.Item title={t('bank-transfer-details.beneficiary-name')}>
                                    {payOffProposal?.beneficiaryName}
                                </DefinitionList.Item>
                                <DefinitionList.Item title={t('bank-transfer-details.beneficiary-iban')}>
                                    {payOffProposal?.beneficiaryIBAN}
                                </DefinitionList.Item>
                                <DefinitionList.Item title={t('bank-transfer-details.reference-number')}>
                                    {contract.contractNumber}
                                </DefinitionList.Item>
                            </DefinitionList>
                        </Layout.Item>
                        <Layout.Item default="1/2">
                            <DefinitionTable className={'u-text-uppercase'}>
                                <DefinitionTable.Item
                                    title={
                                        <Heading level={4} className={'u-m-none'}>
                                            {t('bank-transfer-details.payoff-amount')}
                                        </Heading>
                                    }
                                >
                                    {formatAsCurrency(payOffProposal?.payOffAmount)}
                                </DefinitionTable.Item>
                                <DefinitionTable.Item
                                    title={
                                        <Heading level={5} className={'u-m-none'}>
                                            {t('bank-transfer-details.calculation-date')}
                                        </Heading>
                                    }
                                >
                                    {formatCpDate(payOffProposal?.calculationDate).format('DD-MM-YY')}
                                </DefinitionTable.Item>
                            </DefinitionTable>
                        </Layout.Item>
                    </Layout>
                </DataOverview>
            </Layout.Item>
            <Layout.Item>
                <Notification status={NotificationStatus.warning}>
                    <>
                        <span>{payOffProposal?.paymentDescription}</span>
                        <br />
                        <span>{payOffProposal?.handlingInformation}</span>
                    </>
                </Notification>
            </Layout.Item>
            <Layout.Item>
                <ButtonContainer nav className={'u-mt-small'}>
                    <Button
                        secondary
                        onClick={() => printButtonClick()}
                        testId="printButton"
                        icon="printer"
                        link
                        iconReversed
                        simple
                    >
                        {t('print-button')}
                    </Button>
                </ButtonContainer>

                <ButtonContainer center>
                    <Button secondary onClick={backButtonClick} testId="backButton" type="btn">
                        {t('translation:editableSectionNav.back')}
                    </Button>
                    <Button onClick={dashboardButtonClick} testId="dashboardButton" type="btn">
                        {t('translation:editableSectionNav.dashboard')}
                    </Button>
                </ButtonContainer>
            </Layout.Item>
        </>
    );
};
