import React from 'react';
import { FaqPublic } from 'components/faq/FaqPublic';
import { useAuthentication } from '@cp-shared-5/frontend-ui';
import { FaqPrivate } from 'components/faq/FaqPrivate';

export const FaqPage: React.FC = () => {
    const { isAuthenticated } = useAuthentication();
    if (isAuthenticated) {
        return <FaqPrivate />;
    } else {
        return <FaqPublic />;
    }
};
