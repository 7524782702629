import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { Moment } from 'moment';
import { CPDate, formatCpDate } from '@cp-shared-5/common-utilities';
import { longDateFormat } from '../../../common';

export const getMinValidDate = (): Moment => formatCpDate().add(1, 'day').toMoment();
//TODO: clarify business logic for max valid date calculation (https://jira.platform.vwfs.io/browse/CO-13376)
export const getMaxValidDate = (contractEndDate: CPDate): Moment =>
    formatCpDate(contractEndDate).subtract(1, 'month').toMoment();

export const validationSchema = (t: TFunction, contractEndDate?: CPDate): Yup.ObjectSchema => {
    const translationPrefix = 'future-quote-simulation.date-form.validation';
    return Yup.object().shape({
        payoffDate: Yup.string()
            .trim()
            .required(t(`${translationPrefix}.required`))
            .test('format', t(`${translationPrefix}.invalid-format`), (value) => {
                if (formatCpDate(value, longDateFormat).toCpDate()) return true;
                return false;
            })
            .test('min valid date', t(`${translationPrefix}.min-valid-date`), (value) => {
                return formatCpDate(value, longDateFormat).toMoment().isSameOrAfter(getMinValidDate(), 'day');
            })
            .test('max valid date', t(`${translationPrefix}.max-valid-date`), (value) => {
                if (!contractEndDate) return false;
                return formatCpDate(value, longDateFormat)
                    .toMoment()
                    .isSameOrBefore(getMaxValidDate(contractEndDate), 'day');
            }),
    });
};
