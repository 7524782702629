import {
    Contract,
    ContractError,
    ContractsData,
    ContractStatus,
    getFinancialDetailsEndpoint,
    getInterveningPartiesEndpoint,
    getPostboxDocumentsEndpoint,
    NL_TIMEZONE,
    ProductTypeEnum,
} from '../../common';
import { Error, formatCpDate } from '@cp-shared-5/common-utilities';
import moment from 'moment-timezone';

export const contractWithAllFields: Contract = {
    encryptedContractVersionId: 'NQYth-FzyJEsY_9rH3-iLg',
    encryptedContractNumber: 'IoQkhWSI_VLQQBODN08lqg',
    contractStatus: ContractStatus.ACTIVE,
    contractNumber: '123456',
    carBrand: 'VW',
    carModel: 'Polo',
    licensePlate: '124-12',
    productType: 'DutchFin Financial Lease',
    productTypeEnum: ProductTypeEnum.FINANCIAL_LEASE,
    contractEndDate: formatCpDate(moment.tz('3000-12-20', NL_TIMEZONE)).toCpDate(),
    nextDueDate: formatCpDate(moment.tz('2020-12-20', NL_TIMEZONE)).toCpDate(),
    nextDueAmount: 250,
    outstandingBalance: 125.5,
    _links: {
        financialDetails: getFinancialDetailsEndpoint('NQYth-FzyJEsY_9rH3-iLg', '123456'),
        interveningParties: getInterveningPartiesEndpoint('123456'),
        postboxDocuments: getPostboxDocumentsEndpoint('NQYth-FzyJEsY_9rH3-iLg', 'cnupvXjwzCBKAkuuZ3ffcA'),
    },
    unpaidBalance: {
        unpaidPresent: true,
        pendingInstallments: 5,
        nominalAmount: 1000.42,
        arrearsAmount: 1584.12,
        interestOnArrears: 35,
        totalUnpaidAmount: 14214.1,
        nextDueDate: formatCpDate(moment.tz('2020-12-20', NL_TIMEZONE)).toCpDate(),
        paymentDetailsEntity: '20641',
        paymentDetailsReference: '10854447',
    },
};

export const contractWithoutUnpaidBalance: Contract = {
    ...contractWithAllFields,
    unpaidBalance: undefined,
};

export const expiredContractWithAllFields: Contract = {
    encryptedContractVersionId: 'NQYth-FzyJEsY_9rH3-iLg',
    encryptedContractNumber: 'IoQkhWSI_VLQQBODN08lqg',
    contractStatus: ContractStatus.EXPIRED,
    contractNumber: '56789',
    carBrand: 'VW',
    carModel: 'Polo',
    licensePlate: '124-12',
    productType: 'Prive Plan',
    contractEndDate: formatCpDate(moment.tz('3000-12-20', NL_TIMEZONE)).toCpDate(),
    nextDueDate: formatCpDate(moment.tz('2020-12-20', NL_TIMEZONE)).toCpDate(),
    nextDueAmount: 250,
    outstandingBalance: 125.5,
    _links: {
        financialDetails: getFinancialDetailsEndpoint('NQYth-FzyJEsY_9rH3-iLg', '12345'),
    },
};

export const contractWithMandatoryFields: Contract = {
    encryptedContractVersionId: 'NQYth-FzyJEsY_9rH3-iLg',
    encryptedContractNumber: 'IoQkhWSI_VLQQBODN08lqg',
    carBrand: 'VW',
    contractNumber: '10111213',
    contractStatus: ContractStatus.ACTIVE,
    _links: {
        financialDetails: getFinancialDetailsEndpoint('NQYth-FzyJEsY_9rH3-iLg', '12345'),
    },
};

export const validContracts: Contract[] = [
    contractWithAllFields,
    contractWithMandatoryFields,
    expiredContractWithAllFields,
];

export const contractDataWithValidContracts: ContractsData = {
    contracts: validContracts,
    isContractWithoutMandatoryFieldPresent: false,
};

export const contractDataWithoutUnpaidBalance: ContractsData = {
    contracts: [contractWithoutUnpaidBalance],
    isContractWithoutMandatoryFieldPresent: false,
};

export const noContractsFoundError: Error<ContractError> = {
    status: 502,
    code: 'NO_CONTRACT_FOUND',
    message: 'No contracts found',
};

export const marketApiDefaultBusinessException: Error<ContractError> = {
    status: 502,
    code: 'MARKET_API_INTERNAL_SERVER_ERROR',
    message: 'The market API responded with an error indicating a technical problem',
};

export const marketApiUnavailable: Error<ContractError> = {
    status: 502,
    code: 'MARKET_API_SERVICE_UNAVAILABLE',
    message: 'The market API is currently unavailable',
};

export const apiNotReachableError = {
    status: 502,
    code: 'MARKET_API_DEFAULT_BUSINESS_ERROR',
    message: 'The market API responded with an unexpected/ignored error code',
};
