import { InterveningParty } from '../../../../common';
import {
    DefinitionListHorizontal,
    DefinitionListItem /*, useAnalyticsEnterViewportTracker */,
} from '@cp-shared-5/frontend-ui';
import { Hr } from '@vwfs-bronson/bronson-react';
import { NoConnectionNotification } from 'components/notifications/no-connection/NoConnectionNotification';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const InterveningPartiesUi: React.FC<{ parties?: InterveningParty[] }> = ({ parties }) => {
    const { t } = useTranslation('contracts');
    // const { ref } = useAnalyticsEnterViewportTracker('onOpenContractAccordion', 'Contract Parties');

    if (!parties) {
        return <NoConnectionNotification />;
    }

    const interveningPartiesList = (party: InterveningParty): DefinitionListItem[] => {
        return [
            {
                label: t('intervening-parties.name'),
                value: party.userName,
            },
            {
                label: t('intervening-parties.role.label'),
                value: party.userName ? party.userRole : null,
            },
        ];
    };

    return (
        <>
            {/* <div ref={ref}> */}
            {parties?.map((party) => (
                <div key={`${party.userName}-${party.userRole}`}>
                    <DefinitionListHorizontal list={interveningPartiesList(party).filter((party) => !!party.value)} />
                    {!!party.userName && <Hr className="u-mb-small" />}
                </div>
            ))}
            {/* </div> */}
        </>
    );
};
