import { DefaultMarketApiErrors } from '@cp-shared-5/common-utilities';
import { AbstractDataState, useGetContractBasedApiData } from '@cp-shared-5/frontend-integration';
import { getContractsWithEncryptedEndpoint, ContractsData } from '../../common';
import { fetchEncryptedContract } from './EncryptedContractSlice';
import { EncryptedContractDataSelector } from './EncryptedContractDataSelector';

/**
 * Fetches the contracts for given encrypted contract number. If not found, undefined will be returned as data.
 * @param encryptedContractNumber
 */
export function useContract(encryptedContractNumber: string): AbstractDataState<ContractsData, DefaultMarketApiErrors> {
    return useGetContractBasedApiData(
        encryptedContractNumber,
        fetchEncryptedContract,
        EncryptedContractDataSelector,
        getContractsWithEncryptedEndpoint(encryptedContractNumber),
    );
}
