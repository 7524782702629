import { PostboxCms } from '../../common';
import { ContentSection, Heading } from '@vwfs-bronson/bronson-react';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { Postbox } from 'components/postbox';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useCmsContent } from 'utils/useCmsContent';

export const PostboxPage: React.FC = () => {
    const { encryptedContractNumber } = useParams<{ encryptedContractNumber: string }>();
    const [postboxCms, postboxCmsLoading, postboxCmsError] = useCmsContent<PostboxCms>('postbox');

    const pageCmp: React.FC = () => (
        <ContentSection pageWrapSize="medium">
            <Heading level={1} className="u-mb-large">
                {postboxCms?.title}
            </Heading>
            <Heading level={3}>{postboxCms?.subHeadline}</Heading>
            <Postbox encryptedContractNumber={encryptedContractNumber} />
        </ContentSection>
    );

    const PostboxPageWithHandlers = withLoadingAndNoConnectionHandler(pageCmp);
    return <PostboxPageWithHandlers isLoading={postboxCmsLoading} hasError={!!postboxCmsError} />;
};
