import { SmsForm } from '../../../../common';
import { preventSubmit, ValidatedCheckbox, ValidatedInput } from '@cp-shared-5/frontend-ui';
import { Button, Fieldset, Form, Layout } from '@vwfs-bronson/bronson-react';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { identificationFormValidationSchema } from './validation';

export type FormProps = {
    smsCode: string;
    privacyPolicy: boolean;
};

type SmsIdentificationFormProps = {
    content: SmsForm;
    onSubmit: (values: FormProps) => void;
};

export const SmsIdentificationForm: React.FC<SmsIdentificationFormProps> = ({ content, onSubmit }) => {
    const [formValues, setFormValues] = useState<FormProps>({
        smsCode: '',
        privacyPolicy: false,
    });

    const handleSubmit = (formData: FormProps): void => {
        setFormValues({ ...formData });
        onSubmit(formData);
    };

    return (
        <>
            <Layout.Item>
                <Formik
                    initialValues={formValues}
                    onSubmit={handleSubmit}
                    validationSchema={identificationFormValidationSchema(content.validation)}
                >
                    {(formik) => (
                        <Form onSubmit={preventSubmit()}>
                            <Fieldset>
                                <Fieldset.Row className="u-mb-small">
                                    <ValidatedInput
                                        label={content.inputFieldLabel}
                                        name="smsCode"
                                        tooltip={content.inputFieldTooltip}
                                        testId="sms-code"
                                    />
                                </Fieldset.Row>
                                <Fieldset.Row className="u-mb-small">
                                    <ValidatedCheckbox
                                        label={<div dangerouslySetInnerHTML={{ __html: content.privacyPolicyLabel }} />}
                                        testId="privacy-policy-checkbox"
                                        name="privacyPolicy"
                                        isMandatory={true}
                                    />
                                </Fieldset.Row>
                                <Fieldset.Row className="u-mt">
                                    <Button full={true} onClick={formik.submitForm} testId={'submit-button'}>
                                        {content.submitLabel}
                                    </Button>
                                </Fieldset.Row>
                            </Fieldset>
                        </Form>
                    )}
                </Formik>
            </Layout.Item>
        </>
    );
};
