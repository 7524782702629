import { Contract } from '../../../../common';
import { ContractActions } from '@cp-shared-5/frontend-ui';
import { postboxPagePathWithContractId, totalEarlySettlementPagePath } from 'components/navigation/paths';
import React from 'react';
import { useTranslation } from 'react-i18next';

export type ContractActionItemsProps = {
    contract: Contract;
};

export const ContractActionItems: React.FC<ContractActionItemsProps> = ({ contract }) => {
    const { t } = useTranslation('contracts');
    const { encryptedContractNumber } = contract;

    const actionItems = [
        {
            iconName: 'semantic-download',
            pageUrl: postboxPagePathWithContractId(encryptedContractNumber),
            label: t('actions.postbox'),
            tooltip: '',
        },
    ];

    if (contract.contractStatus === 'ACTIVE') {
        actionItems.push({
            iconName: 'semantic-calculator',
            pageUrl: totalEarlySettlementPagePath(encryptedContractNumber),
            label: t('actions.total-early-settlement'),
            tooltip: '',
        });
    }

    return (
        <div className="u-pb-small u-mt-xxsmall">
            <ContractActions contractActionItems={actionItems} />
        </div>
    );
};
