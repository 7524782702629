import { configureStore, Action, EnhancedStore } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';

import rootReducer, { RootState } from './rootReducer';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createStore(): EnhancedStore<any, any> {
    const store = configureStore({
        reducer: rootReducer,
    });

    return store;
}

const store = createStore();

if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./rootReducer', () => {
        const newRootReducer = require('./rootReducer').default;
        store.replaceReducer(newRootReducer);
    });
}

export type AppDispatch = typeof store.dispatch;
// TODO Think about moving it out to shared packages as AppThunk<T> type
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export default store;

export type AppStore = typeof store;

export type AppState = RootState;
