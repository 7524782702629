export function getSmsSendingEndpoint(): string {
    return '/sms/send';
}

export function getVerifySmsEndpoint(): string {
    return '/customer/verify/sms';
}

export function getOpenVerificationEndpoint(): string {
    return '/customer/verify/openVerification';
}
